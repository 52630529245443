import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import './scss/style.scss'
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import deCodeToken from 'jwt-decode'
import Swal from 'sweetalert2';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const TokenCheck = () => {
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decoded = deCodeToken(token);
      console.log(decoded.exp , Date.now() / 1000);
      if (decoded.exp < Date.now() / 1000) {
        localStorage.removeItem('authToken');
        Swal.fire({
          title: 'Session expired',
          text: "Your session has been expired. Please login again",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Go to login page'
        }).then(() => {
          window.location.reload();
        });
      }
    }
  }, [location.pathname]);

  return null; // No UI for this component
}


const App = () => {
   // after calling LogRocket.init()
   LogRocket.init('i4jcug/mytpt-app', {
    dom: {
      inputSanitizer: true,
    },
  });

  setupLogRocketReact(LogRocket);

  /* useEffect(() => {
    console.log("here",window.location.pathname);
    if (localStorage.getItem('currentUser') && window.location.pathname !== "/base/adduser") {
      console.log("here")
      localStorage.removeItem('currentUser');
      // localStorage.clear();
    }
  }, [window.location.pathname]) */

  return (
    <BrowserRouter>
    <TokenCheck />
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
export default App